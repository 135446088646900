import React from "react";
import { Button, Container, Row, Col } from "reactstrap";
import DemoNavbar from "components/Navbars/DemoNavbar.js";


const AboutUs = () => {
  return (
    <>
      <DemoNavbar />
      <div className="position-relative">
        <section className="section section-shaped pb-50">
          <div className="shape shape-style-1 shape-default">
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
          </div>        
          {/* SVG separator */}
          <div className="separator separator-bottom separator-skew">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="fill-white"
                points="2560 0 2560 100 0 100"
              />
            </svg>
          </div>
        </section>
        <section className="section section-lg">
          <Container>
            <h4>About Us</h4>
            <p>
              Few social minded people got to gather in Galhinna, 
              Sri Lanka in the year 2012 and founded Dhurham College with thirty-two students 
              and four teachers by visionary educators to provide English medium education up to Grade 11. 
              They were of a humble back ground and beginning of DC was modest. 
              And they set ''Healthy Education Era'' as their motto to full fill the National Educational Goals
              Today, we have efficient educated board of governance; experienced principal 
              in developing national education system, the school is co-educational 
              and also committed to the pursuit of excellence in teaching and learning in Pre-school, 
              Primary and Middle Schools.
            </p>
            <p>
              Dhurham allows the students to grow with international ideals and character, 
              in a multi-ethnic, multi-cultural and multi-religious school environment. 
              Academic and co-curricular programmes conduct by Dhurham College
            </p>

            <h4>Vision</h4>
            <p>Towards The Quality Education to Build Up the Prosperous Generation</p>

            <h4>Mission</h4>
            <p>
              We shall render our dedicated services to produce a younger generation 
              with thoughts of unity in nation building in accordance with Healthy leadership skills, 
              higher ethics and greater personalities based upon the nation's educational goals.
            </p>

            <h4>Core Values</h4>
            <h6>Integrity</h6>
            <p>
              Our students are being honest and having strong moral principles.
            </p>

            <h6>Civility</h6>
            <p>
              Our students greet people with civility.
            </p>

            
            <h6>Diversity</h6>
            <p>
              Students should be adequately reflect the ethnic and cultural diversity of the country.
            </p>

            <h4>Inception</h4>
            <p>
              15th January 2012
            </p>

            <h4>Founded Principal</h4>
            <p>
              Mr. Anas Aflardeen
            </p>
          </Container>
        </section>
      </div>
    </>
  );
}

export default AboutUs;