import React from "react";
import { Button, Container, Row, Col } from "reactstrap";
import DemoNavbar from "components/Navbars/DemoNavbar.js";

const Events = () => {
  return (
    <>
      <DemoNavbar />
      <div className="position-relative">
        <section className="section section-shaped pb-50">
          <div className="shape shape-style-1 shape-default">
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
          </div>        
          {/* SVG separator */}
          <div className="separator separator-bottom separator-skew">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0"
            >
              <polygon
                className="fill-white"
                points="2560 0 2560 100 0 100"
              />
            </svg>
          </div>
        </section>
        <section className="section section-lg">
          <Container>
            <h5>Our Events</h5>
            <p>
              Dhurham organizes several Events in extra and co-curricular to make every student 
              to gain and develop knowledge, skills and attitudes. 
              We clearly understand the uniqueness of each student and give our efforts to cater everyone through these types’ activities. 
              Engage with a different community, practically understand the value of time, 
              do things in conditional environment and understand the 
              importance of develop the power of observation are the key objectives of our annual events. 
            </p>
            <ul>
              <li>Following are also some significant developments we expect from these events</li>
              <li>Develop Soft Skills especially Time Management, Observation and Listening Skills.</li>
              <li>Enhance the affectionate relationship among the students.</li>
              <li>Explore the student’s Knowledge broadly.</li>
              <li>Increase the involvement in Education inside and at home and develop performance. </li>
              <li>Develop Moral Values and Etiquettes.</li>
            </ul>

            <h5>Some of our Past Key Events</h5>
            <h6>Outdoor Activities</h6>
            <p>
              All our programs and activities have clear objectives.  
              Annually we organize outdoor activities such as Indoor Cricket Matches, Swimming sessions and Badminton etc. ... 
              These activities develop memory power through Motor Skills. 
              Our students of Grade 05 and 06 boys had participated in an interesting 
              Indoor Cricket Session with our Sri Lankan National Cricketer Asela Gunaratne.
            </p>
            <h6>Annual Excavations</h6>
            <p>
              Mutual understanding between Teachers and Students is an utmost need of the time in developing 
              the performance of students. In order to ensure, 
              we plan different activities in our calendar and Annual Excavation is one of our vital events.  
              We had organized an Educational Tour during the second term to see the beauty of our Motherland. 
              Teachers and Students enjoyed very much, visiting different destinations.
            </p>
            <h6>Get Together</h6>
            <p>
              Great Teachers inspire students to face challenging world in smart. 
              Ensure and sustain the same pace throughout the year and long is very important. 
              We organize Get together to the teachers on the first day of the term in order to recognize the service they provide in the past and to Adopt quickly to Learning-Teaching environment. 
            </p>
            <h6>National Day Commemorations</h6>
            <p>
              It’s a bounded duty of every elders especially Teachers and parents to educate the 
              importance of being patriot and nationalist. Practically showing this, 
              we celebrate our Independence Day with the participation of our Students, 
              Teachers, Parents and distinguished people.
            </p>
            <h6>Annual Sports Meet</h6>
            <p>
              Annual Sports Meet is one of the biggest events in our calendar. 
              With the thorough understanding we annually organize Inter house Sports Meet to create a students’ 
              community with physically fit and greater moral value. 
              Practically getting knowledge to develop personality and to continue the same for life also an 
              expected outcome of this event. In our last event students enthusiastically 
              participated in the events and show their skills to the public. 
              The event was graced by some Prominent Educationists, Principals, Parents and well-wishers.
            </p>
            <h6>Annual Concert</h6>
            <p>
              Every Students are unique and has different skills. 
              In order to give opportunities to everyone we have variety of events and activities. 
              Annual Concert is such an outstanding event which gives opportunity to show their performance on the stage. 
              We train our students via Students’ Forums and create the platform to perform in the Concert. 
              All the events of the Annual Concert are planned with the firm trust of developing Presentation Skills and Self Confidence.
            </p>
          </Container>
        </section>
      </div>
    </>
  );
}

export default Events;